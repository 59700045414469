<template>
  <div>
    <CCard>
      <CForm @submit.prevent="submit">
          <CCardHeader>
            <strong>Agregar lecciones</strong>
          </CCardHeader>
          <CCardBody>
              <CInput
                label="Título de la lección"
                placeholder="Introduce el título del curso"
                v-model="form.title"
                required
              />
              <editor
                v-model="form.content"
                api-key="lq9o8ek56miy2mhc4efar06aiwn0cr39tts44dxhu5gnps39"
                :init="{
                  height: 500,
                  plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                  menubar: 'file edit view insert format tools table help',
                  toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                  toolbar_sticky: true,
                  }"
              />
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" @click="() => $router.push('/course') ">
              Salir
            </CButton>
            <CButton color="success" class="float-right" type="submit">
              Guardar <CSpinner v-if="loading" variant="grow" size="sm"/>
            </CButton>
          </CCardFooter>
        </CForm>
      </CCard>
      <CToaster :autohide="3000">
      <template v-for="(item, index) in toasts">
        <CToast
          :key="`${item.title}-${index}`"
          :show="true"
          :color="item.color"
          :header="item.title"
        >
          {{ item.message }}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import axios from 'axios'
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'Lesson',
  components: {
     'editor': Editor
  },
  data: function () {
    return {
        form: {
          title: '',
          content: '',
          course_id: null
        },
        loading: false,
        show: true,
        isCollapsed: true,
        toasts: [],
        loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.'
    }
  },
  methods: {
    submit() {
      this.loading= true
      const token = this.$cookies.get('login_token')
      this.form.course_id = this.$route.params.id
      axios
        .post(process.env.VUE_APP_BACKEND_URL+'/api/lesson', this.form, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
          this.form = {
            title: '',
            content: ''
          },
          this.$emit('submitted', '')
          this.loading = false
          this.toasts.push({
            title: "Confirmación",
            color: "success",
            message: "Leccion creada con exito",
          });
          window.scrollTo(0,0);
        })
    }
  }
}
</script>
