<template>
  <div>
    <CRow>
      <CCol sm="6" md="4">
        <CCard>
          <CCardHeader>
            <strong>{{ course.title }}</strong>
          </CCardHeader>
          <CCardBody>
            <ul>
              <li style="cursor: pointer" v-for="lesson in lessons" :key="lesson.id" @click="$router.push('/course/' + $route.params.id + '/lessons/' + lesson.id + '/edit')">
                {{ lesson.title }} <CIcon name="cil-pencil"  />
              </li>
            </ul>
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" @click="$router.push('/course/' + $route.params.id + '/quiz/create')">
              Crear quiz
            </CButton>
            <CButton v-if="edit" class="float-right" color="info" @click="$router.push('/course/' + $route.params.id + '/lessons/create')">
              Crear lección
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
      <CCol sm="6" md="8">
        <CreateLesson v-if="!edit" v-on:submitted="reload" />
        <EditLesson v-if="edit" v-on:submitted='reload' />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import CreateLesson from './CreateLesson.vue'
import EditLesson from './EditLesson.vue'
export default {
  name: 'Lessons',
  components: {
    'CreateLesson': CreateLesson,
    'EditLesson': EditLesson
  },
  data: function () {
    return {
      course: {},
      lessons: [],
      active_index: 0,
      show: true,
      edit: false,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.'
    }
  },
  mounted() {
    if (this.$route.params.lesson_id != undefined) {
      this.edit = true;
    }
      const token = this.$cookies.get('login_token')
      axios
        .get(process.env.VUE_APP_BACKEND_URL+'/api/course/' + this.$route.params.id, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            this.course = response.data.data;
        })

      axios
        .get(process.env.VUE_APP_BACKEND_URL+'/api/course/' + this.$route.params.id + '/lessons', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            this.lessons = response.data.data;
        })
  },
  methods: {
    reload() {
      const token = this.$cookies.get('login_token')
      axios
        .get(process.env.VUE_APP_BACKEND_URL+'/api/course/' + this.$route.params.id + '/lessons', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            this.lessons = response.data.data;
        })
    }
  }
}
</script>
